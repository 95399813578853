import React from 'react';
import { Helmet } from 'react-helmet-async';

const expertsData = [
    { id: 1, name: 'Игорь Манн', country: 'Россия', description: 'один из самых известных российских маркетологов, автор бестселлеров по маркетингу и брендингу.' },
    { id: 2, name: 'Сергей Жуковский', country: 'Украина', description: 'известный маркетолог, автор книг и статей по маркетингу, организатор множества маркетинговых событий и тренингов.' },
    { id: 3, name: 'Анвар Агаев', country: 'Азербайджан', description: 'известный специалист по цифровому маркетингу, основатель и генеральный директор маркетингового агентства.' },
    { id: 4, name: 'Армен Саркисян', country: 'Армения', description: 'основатель маркетингового агентства, известен своими креативными кампаниями и стратегиями для брендов.' },
    { id: 5, name: 'Максим Попов', country: 'Беларусь', description: 'маркетолог с сильным портфолио в области цифрового маркетинга и рекламы.' },
    { id: 6, name: 'Данияр Алимбаев', country: 'Казахстан', description: 'специалист по маркетинговым стратегиям и брендингу, руководитель маркетингового агентства.' },
    { id: 7, name: 'Азиза Юсупова', country: 'Киргизия', description: 'эксперт в области цифрового маркетинга, внесла значительный вклад в развитие маркетинговой индустрии своей страны.' },
    { id: 8, name: 'Андрей Себрант', country: 'Россия', description: 'директора по стратегическому маркетингу сервисов компании «Яндекс»' },
    { id: 9, name: 'Зарина Рахимова', country: 'Таджикистан', description: 'маркетолог с опытом работы в крупных проектах, включая запуск брендов и продвижение продуктов на местном рынке.' },
    { id: 10, name: 'Айгуль Какабаева', country: 'Туркменистан', description: 'маркетолог и консультант по брендингу, известная своими успешными кампаниями для местных предприятий.' },
    { id: 11, name: 'Шахзода Махмудова', country: 'Узбекистан', description: 'специалист по цифровому маркетингу, который проводит обучение и консультации для растущих компаний в Узбекистане.' },
    { id: 12, name: 'Нино Карумидзе', country: 'Грузия', description: 'известный маркетолог, который сделал значительный вклад в развитие цифрового маркетинга в Грузии. Она основала успешное маркетинговое агентство, которое специализируется на интегрированных маркетинговых стратегиях, включая социальные медиа, контент-маркетинг и бренд-менеджмент. Нино также активно участвует в образовательных программах, проводя семинары и мастер-классы для молодых специалистов в области маркетинга.' },
  ];

function MembersBA() {
  return (
    <div className="xContent p-box py-6">
      <Helmet>
        <title>Члены правления</title>
        <meta
        name="description"
        content="Члены правления Международной Ассоциации маркетологов СНГ"
        />
        <link rel="canonical" href="https://marketing-association.com/membersBA" />
      </Helmet>
      <h1 className="title">Члены правления Международной Ассоциации маркетологов СНГ:</h1>
      <div className="expertsTable">
      <table>
        <thead>
          <tr>
            <th>№</th>
            <th>ФИО</th>
            <th>Страна</th>
            <th>Достижения</th>
          </tr>
        </thead>
        <tbody>
          {expertsData.map(expert => (
            <tr key={expert.id}>
              <td>{expert.id}</td>
              <td>{expert.name}</td>
              <td>{expert.country}</td>
              <td>{expert.description}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
    </div>
  );
}

export default MembersBA;
